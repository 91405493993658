import React from "react";
import { Link, useLocation } from "react-router-dom";
import getUserRole from "../../Auth/getUserRole";
import AuthService from "../../Services/auth.service"

export default function Sidebar({active}:{active:boolean}) {
  const location = useLocation();
  const userRole = getUserRole();
  // Define an array of menu items with URL and title
  const menuItems = [
    {
      url: "/dashboard",
      param: "dashboard",
      title: "Dashboard",
      roles: ["superadmin","admin", "respo_dashboard"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    /* {
      url: "/dashboarddistrict",
      param: "dashboarddistrict",
      title: "Dashboard District",
      roles: ["superadmin","admin", "respo_dashboard_district"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      url: "/dashboardtypeassociation",
      param: "dashboardtypeassociation",
      title: "Dashboard Partie/Assoc.",
      roles: ["superadmin","admin", "respo_dashboard_district"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3 6a3 3 0 013-3h2.25a3 3 0 013 3v2.25a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm9.75 0a3 3 0 013-3H18a3 3 0 013 3v2.25a3 3 0 01-3 3h-2.25a3 3 0 01-3-3V6zM3 15.75a3 3 0 013-3h2.25a3 3 0 013 3V18a3 3 0 01-3 3H6a3 3 0 01-3-3v-2.25zm9.75 0a3 3 0 013-3H18a3 3 0 013 3V18a3 3 0 01-3 3h-2.25a3 3 0 01-3-3v-2.25z"
            clipRule="evenodd"
          />
        </svg>
      ),
    }, */
    {
      url: "/member/list",
      param: "membre",
      title: "Membres",
      roles: ["superadmin", "admin", "impression", "respo_dashboard_district", "impression_excel", "admin_impression"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
            clipRule="evenodd"
          />
          <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
        </svg>
      ),
    },
    {
      url: "/add/member",
      param: "membre",
      title: "Ajout Membre",
      roles: ["superadmin","admin", "saisie", "respo_dashboard_district"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      ),
    },

    {
      url: "/score",
      param: "score",
      title: "Score",
      roles: ["superadmin","admin"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      url: "/recompense",
      param: "recompense",
      title: "Récompenses",
      roles: ["superadmin","admin"],
      svgPath: (
        <svg  
          viewBox="0 0 20 20" 
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path 
          fillRule="evenodd"
          d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM13.22 15.39L10 13.45L6.78 15.39C6.4 15.62 5.93 15.28 6.03 14.85L6.88 11.19L4.05 8.74C3.72 8.45 3.9 7.9 4.34 7.86L8.08 7.54L9.54 4.09C9.71 3.68 10.29 3.68 10.46 4.09L11.92 7.53L15.66 7.85C16.1 7.89 16.28 8.44 15.94 8.73L13.11 11.18L13.96 14.85C14.06 15.28 13.6 15.62 13.22 15.39Z" fill="white"/>
        </svg>
      ),
    },
    {
      url: "/users",
      param: "users",
      title: "Gestion Utilisateurs",
      roles: ["admin", "superadmin"],
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
            clipRule="evenodd"
          />
          <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
        </svg>
      ),
    },
    // {
    //   url: "/motif",
    //   param: "motif",
    //   title: "Motif",
    //   roles: ["admin", "superadmin"],
    //   svgPath: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 24 24"
    //       fill="currentColor"
    //       className="w-6 h-6"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
    //         clipRule="evenodd"
    //       />
    //       <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
    //     </svg>
    //   ),
    // },
    {
      url: "/event/list",
      param: "event",
      title: "Evènement",
      roles: ["superadmin","admin"],
      svgPath: (
        <svg 
        viewBox="0 0 24 24" 
        fill="currentColor" 
        xmlns="http://www.w3.org/2000/svg">
        <path 
        fillRule="evenodd"
        d="M16 13H13C12.45 13 12 13.45 12 14V17C12 17.55 12.45 18 13 18H16C16.55 18 17 17.55 17 17V14C17 13.45 16.55 13 16 13ZM16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3ZM18 20H6C5.45 20 5 19.55 5 19V9H19V19C19 19.55 18.55 20 18 20Z" fill="white"/>
        </svg>
      ),
    },
    {
      url: "/users/statistic",
      param: "statistic",
      title: "Statistiques",
      roles: ["superadmin","admin"],
      svgPath: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
        </svg>
      ),
    },
    {
      url: "/",
      param: "action",
      title: "Déconnexion",
      svgPath: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
          />
        </svg>
      ),
    },
  ];
  
  const handleLogout = () => {
    AuthService.logout();
  };
  
  const isAuthorized = (menuItemRoles: string[] | undefined, param: string, userRole: string) => {
    if (param === "action") {
      return true; // Always display the "Déconnexion" menu item for all roles
    }
  
    if (!menuItemRoles || menuItemRoles.includes(userRole)) {
      return true;
    }
  
    return false;
  };
  
  return (
    <nav
      className={`sidebar sidebar-offcanvas p-4 bg-dark`}
      id="sidebar"
      style={{ right: active ? "auto" : -260 }}
    >
      <ul className="nav ">
        {menuItems.map((menuItem, index) => {
          if (isAuthorized(menuItem.roles, menuItem.param, userRole)) {
            return (
              <li
                key={index}
                className={`nav-item border-nav-item p-1 mt-3 ${
                  location.pathname === menuItem.url ? "bg-orange" : ""
                }`}
              >
                {menuItem.param === "action" ? (
                  <a
                    className="nav-link"
                    href="#"
                    onClick={handleLogout}
                  >
                    {menuItem.svgPath}
                    <span className="ms-2 menu-title">{menuItem.title}</span>
                  </a>
                ) : (
                  <Link className="nav-link" to={menuItem.url}>
                    {menuItem.svgPath}

                    {menuItem.param === "dashboardtypeassociation" ? (
                      // HTML elements to render when condition is true
                      <span className="ms-1 text-white fs-12">{menuItem.title}</span>
                    ) : (
                      // HTML elements to render when condition is false
                      <span className="ms-2 menu-title">{menuItem.title}</span>
                    )}
                  </Link>
                )}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </nav>
  );
}
