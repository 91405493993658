import axios, { formToJSON } from "axios";
import { BASE_URL } from "../Constant/Url";
import authHeader from "./auth.header";

class ProfileService {
  async getProfile(id) {
    try {
      const response = await axios.get(
        BASE_URL + "/membre/" + id,
        authHeader()
      );
      
      return response.data;
    } catch (error) {
      throw error.response.data.errorMessage;
    }
  }
  
  async getEvenement(id) {
    try {
      const response = await axios.post(
        BASE_URL + "/evenement/",
        id,
        authHeader()
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getParrain(id) {
    try {
      const response = await axios.get(
        BASE_URL + "/membre/parrain/" + id,

        authHeader()
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async uploadFile(file) {
    try {
      const response = await axios.post(BASE_URL + "/uploads", file, {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.log("response received error ", error);
      throw error;
    }
  }

  async uploadMemberImage(file, id_membre) {
    try {
      const response = await axios.post(BASE_URL + "/membre/updatePhoto/"+id_membre, file, authHeader());
      return response.data;
    } catch (error) {
      console.log("response received error ", error);
      throw error;
    }
  }
}

export default new ProfileService();
