import { lazy } from "react";
import EditMembre from "../Presentation/Pages/Member/EditMembre";

const CreateRecompense = lazy(
  () => import("../Presentation/Pages/Recompense/CreateRecompense")
);
const AddMember = lazy(
  () => import("../Presentation/Pages/AddMember/AddMember")
);
const MemberList = lazy(
  () => import("../Presentation/Pages/Member/MemberList")
);
const TodoList = lazy(() => import("../Presentation/Pages/Todo/TodoList"));
const Partisan = lazy(
  () => import("../Presentation/Pages/Partisan/PartisanCreate")
);
const Dashboard = lazy(
  () => import("../Presentation/Pages/Dashboard/Dashboard")
);
const DashboardDistrict = lazy(
  () => import("../Presentation/Pages/DistrictDashboard/DistrictDashboard")
);
const DashboardType = lazy(
  () => import("../Presentation/Pages/TypeAssociationDashboard/TypeAssociationDashboard")
);
const MemberProfile = lazy(
  () => import("../Presentation/Pages/Member/MemberProfile")
);
const Score = lazy(() => import("../Presentation/Pages/Score/Score"));
const Recompense = lazy(
  () => import("../Presentation/Pages/Recompense/Recompense")
);
const Aide = lazy(
  () => import("../Presentation/Pages/Aide/Aide")
);
const ListAide = lazy(
  () => import("../Presentation/Pages/Aide/ListAide")
);
const CreateAide = lazy(
  () => import("../Presentation/Pages/Aide/CreateAide")
);
const EventList = lazy(() => import("../Presentation/Pages/Event/EventList"));
const EventCreate = lazy(
  () => import("../Presentation/Pages/Event/EventCreate")
);
const EventDetail = lazy(
  () => import("../Presentation/Pages/Event/EventDetail")
);
const UserList = lazy(() => import("../Presentation/Pages/User/UserList"));
const Motif = lazy(() => import("../Presentation/Pages/Motif/Motif"));
const ListRecompense = lazy(
  () => import("../Presentation/Pages/Recompense/ListRecompense.jsx")
);
const UserStatistic = lazy(() => import("../Presentation/Pages/User/UserStatistic"));
const UserCreate = lazy(() => import("../Presentation/Pages/User/UserCreate"));
const MotifCreate = lazy(() => import("../Presentation/Pages/Motif/MotifCreate"));
const MotifUpdate = lazy(() => import("../Presentation/Pages/Motif/MotifUpdate"));
const UserEdit = lazy(() => import("../Presentation/Pages/User/UserEdit"));

const coreRoutes = [
  {
    path: "/todo",
    title: "Todo",
    component: TodoList,
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
    role : ["superadmin", "admin", "respo_dashboard"]
  },
  /* {
    path: "/dashboarddistrict",
    title: "Dashboard District",
    component: DashboardDistrict,
    role : ["superadmin","admin", "respo_dashboard_district"] 
  }, */
  /* {
    path: "/dashboardtypeassociation",
    title: "Dashboard Type",
    component: DashboardType,
    role : ["superadmin","admin", "respo_dashboard_district"] 
  }, */
  {
    path: "/member/list",
    title: "Membres",
    component: MemberList,
    role : ["superadmin", "admin", "impression", "respo_dashboard_district", "impression_excel", "admin_impression"]
  },

  {
    path: "/member/profile",
    title: "Membres Profile",
    component: MemberProfile,
    role : ["superadmin", "admin", "impression", "respo_dashboard_district"]
  },

  {
    path: "/add/partisan",
    title: "Ajouter un partisan",
    component: Partisan,
    role : ["superadmin", "admin", "saisie"]
  },
  {
    path: "/membre/edit/:id",
    title: "Modifier",
    component: EditMembre,
    role : ["superadmin", "admin", "impression"]
  },
  {
    path: "/add/member",
    title: "AddMember",
    component: AddMember,
    role : ["superadmin", "admin", "saisie", "respo_dashboard_district"]
  },
  {
    path: "/score",
    title: "Score",
    component: Score,
    role : ["superadmin", "admin"]
  },
  {
    path: "/recompense",
    title: "Recompense",
    component: Recompense,
    role : ["superadmin", "admin"]
  },
  {
    path: "/recompense/create",
    title: "CreateRecompense",
    component: CreateRecompense,
    role : ["superadmin", "admin"]
  },
  {
    path: "/recompense/edit",
    title: "CreateRecompense",
    component: CreateRecompense,
    role : ["superadmin", "admin"]
  },
  {
    path: "/recompense/list",
    title: "Liste des recompenses",
    component: ListRecompense,
    role : ["superadmin", "admin"]
  },
  {
    path: "/event/list",
    title: "Event list",
    component: EventList,
    role : ["superadmin", "admin"]
  },
  {
    path: "/event/detail",
    title: "Event detail",
    component: EventDetail,
    role : ["superadmin", "admin"]
  },
  {
    path: "/event/create",
    title: "Event create",
    component: EventCreate,
    role : ["superadmin", "admin"]
  },

  {
    path: "/users",
    title: "Users",
    component: UserList,
    role : ["superadmin", "admin"]
  },
  {
    path: "/motif",
    title: "Motif",
    component: Motif,
    role : ["superadmin", "admin"]
  },
  {
    path: "/motif/create",
    title: "Motif",
    component: MotifCreate,
    role : ["superadmin", "admin"]
  },
  {
    path: "/motif/update",
    title: "Motif",
    component: MotifUpdate,
    role : ["superadmin", "admin"]
  },
  {
    path: "/users/statistic",
    title: "Statistic",
    component: UserStatistic,
    role : ["superadmin", "admin"]
  },
  {
    path: "/users/create",
    title: "User create",
    component: UserCreate,
    role : ["superadmin", "admin"]
  },
  {
    path: "/users/update",
    title: "User update",
    component: UserEdit,
    role : ["superadmin", "admin"]
  },
];
const routes = [...coreRoutes];
export default routes;
